import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "./style";
import Header from "./components/Header/Header";
import Router from './Routes/Router';
import 'react-toastify/dist/ReactToastify.css';
import PedidoContext from "./components/PedidoContext/PedidoContext";

function App() {
  return (
    <BrowserRouter>
      <PedidoContext>
        <Header/>
        <Container>
          <Router/>
        </Container>
      </PedidoContext>
    <ToastContainer autoClose={4000}/>
    </BrowserRouter>
  );
}

export default App;
