import styled from "styled-components";

export const Container = styled.div`
  padding: 0 2%;
  hr{
    background: #ac8440;
    margin-top: 0;
  }

  span{
    font-size: 12px;
    margin-left: 25px;
    color: #ac8440;
  }

  ul{
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  transition: 0.2;
  h3, h5{
    margin-bottom: 2px;
  }
  h3{
    color: #622d26;
    font-size: 22px;
  }
  h5{
    color: #622d26;
    font-size: 14px;
  }

  &:hover{
    cursor: pointer;
  }
`;

export const ItemContainer = styled.li`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  width: 300px;
  margin: 0 auto auto;
  color: #622d26;
  border: 1px solid #ac8440;
  margin-bottom: 10px;

  /* img{
    width: 200px;
    height: 200px;
  } */

  .card-body{
    padding: 0;
    text-align: center;
  }
`;

export const CounterArea = styled.div`
  display: flex;
  justify-content: center;
  span{
    margin: 0;
    color: #622d26;
  }
  button{
    margin: 0 10px;
    padding: 0 2px;
    color: #622d26;
    border: 1px solid #ac8440;
    display: flex;
    align-items: center;
  }
`;