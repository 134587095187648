import styled from "styled-components";

export const Container = styled.div`

`;

export const NavHeader = styled.div`
  display: flex;
  justify-content: center;
  background: #622d26;

  img{
    max-height: 45px;
    max-width: 45px;
    border-radius: 50%;
  }

  button{
    color: #ac8440;
  }
  span{
    color: #ac8440;
  }
`;

export const DivCollapse = styled.div`
  background: #622d26;
  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
      margin-left: 20px;
      a{
        color: #ac8440;
        font-weight: bold;
      }
    }
  }
`;