import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2%;
  h3{
    color: #622d26;
    font-size: 22px;
    margin: 10px 0;
  }
  ul{
    display: flex;
    flex-direction: column;
  }
  h4{
    width: 100%;
    text-align: center;
    color: #622d26;
  } 
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 5px;
  margin-top: 20px;
  width: 100%;
  button{
    color: #622d26;
    background-color: #ac8440;
    border: 1px solid #ac8440;
    &:hover, &:focus, &:active{
        color: #622d26;
        background-color: #ac8440 !important;
        box-shadow: none !important;
        border-color: #ac8440 !important;
      }
  }
  button + button{
    color: #ac8440;
    background-color: #622d26;
    border: 1px solid #622d26;
    img{
        margin-left: 5px;
        height: 25px;
        width: 25px;
      }
    &:hover, &:focus, &:active{
      color: #ac8440;
      background-color: #622d26 !important;
      box-shadow: none !important;
      border-color: #622d26 !important;
    }
  }
`;