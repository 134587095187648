import cookieTradicional from "../../../assets/cardapio/Tradicional.png";
import cookieChocolate from "../../../assets/cardapio/Chocolate.png";
import cookieCafe from "../../../assets/cardapio/Cafe.png";
import cookieCanelaFit from "../../../assets/cardapio/Fit_canela.png";
import cookieChocolateFit from "../../../assets/cardapio/Fit_chocolate.png";
import poteTradicional from "../../../assets/cardapio/Pote_tradicional.png";
import poteLeite from "../../../assets/cardapio/Leite.png";
import poteCanela from "../../../assets/cardapio/Canela.png";
import brownieTradicional from "../../../assets/cardapio/Marmita.png";
import cookieKids from "../../../assets/cardapio/Cookie_kid.png";
import brownieKids from "../../../assets/cardapio/Brownie_kid.png";

export const cookies = {
  title: "Cookies",
  subtitle: "Combo com 5 unidades (sabores a sua escolha)",
  preco: 'R$10,00',
  list: [
    {
      nome: 'Cookie Kids',
      descricao: 'Cookie artesanal de massa de trigo e confeitos coloridos de chocolate.',
      preco: 'R$2,00',
      img: cookieKids
    },
    {
      nome: 'Cookie Tradicional',
      descricao: 'Cookie artesanal de massa de trigo e gotas de chocolate.',
      preco: 'R$2,00',
      img: cookieTradicional
    },
    {
      nome: 'Cookie de Chocolate',
      descricao: 'Cookie artesanal de massa de trigo, toque de chocolate e gotas de chocolate branco.',
      preco: 'R$2,00',
      img: cookieChocolate
    },
    {
      nome: 'Cookie de Café',
      descricao: 'Cookie artesanal de massa de trigo e um toque de café',
      preco: 'R$2,00',
      img: cookieCafe
    },
  ]
}

export const cookiesFit = {
  title: "Cookies Fit",
  subtitle: "Combo com 5 unidades (sabores a sua escolha)",
  preco: 'R$15,00',
  list: [
    {
      nome: 'Cookie de canela',
      descricao: 'Cookie artesanal de massa de aveia com xilitol e um toque de canela.',
      preco: 'R$3,00',
      img: cookieCanelaFit
    },
    {
      nome: 'Cookie de chocolate 50%',
      descricao: 'Cookie artesanal de massa de aveia com xilitol e um toque de cacau 50%.',
      preco: 'R$3,00',
      img: cookieChocolateFit
    }
  ]
}

export const potes = {
  title: "Cookies de Pote",
  preco: 'R$12,00',
  list: [
    {
      nome: 'Cookie de Pote de brigadeiro',
      descricao: 'Deliciosa farofa de cookie com maravilhoso brigadeiro.',
      preco: 'R$12,00',
      img: poteTradicional
    },
    {
      nome: 'Cookie de Pote de leite em pó',
      descricao: 'Deliciosa farofa de cookie de chocolate com  maravilhoso brigadeiro de leite em pó.',
      preco: 'R$12,00',
      img: poteLeite
    },
    {
      nome: 'Cookie de Pote de canela',
      descricao: 'Deliciosa farofa de cookie de café com maravilhoso brigadeiro de canela.',
      preco: 'R$12,00',
      img: poteCanela
    },
  ]
}

export const brownies = {
  title: "Marmita de brownie com cookies",
  preco: "R$20,00",
  list: [
    {
      nome: "Marmitinha kids",
      descricao: "A combinação do nosso delicioso cookie kids, brigadeiro de leite em pó e brownie com confeitos coloridos de chocolate.",
      preco: "R$20,00",
      img: brownieKids
    },
    {
      nome: "Marmitinha de brownie",
      descricao: "A combinação do nosso delicioso cookie tradicional, brigadeiro de leite em pó e brownie.",
      preco: "R$20,00",
      img: brownieTradicional
    }
  ]
}