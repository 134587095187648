import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from '../pages/home/Home';
import Carrinho from "../pages/Carrinho/Carrinho";

const Router = () => {

  return(
    <Switch>
      <Route path='/' component={Home}/>
      <Redirect to='/'/>
    </Switch>
  )
}

export default Router;