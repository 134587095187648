import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5%;

  img{
    width: 200px;
  }

  h1, h4{
    margin-top: 10px;
  }

  h1{
    color: #622d26;
  }

  h4{
    color: #ac8440;
  }
`;

export const InfoArea = styled.div`
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  align-items: center;

  @media(min-width: 800px){
    flex-direction: row;
    justify-content: space-around;
  }

  button {
    max-width: 300px;
    margin: 10px 0;
    background: #622d26 !important;
    color: #ac8440;
    border: #622d26 !important;
    a{
      color: #ac8440;
      text-decoration: none;
      img{
        margin-left: 5px;
        height: 25px;
        width: 25px;
      }
    }
  }
  button:hover, button:focus, button:active{
    border: initial;
    color: #ac8440;
    box-shadow: none !important;
  }
`;

export const Content = styled.div`
  padding-top: 20px;
`;

export const CarouselContainer = styled.div`
    height: auto;
    width: 100%;
    img{
      height: auto;
      width: 100%;
      @media(min-width: 800px){
        width: auto;
        max-height: 400px;
        margin-left: 22%;
      }
    }
`;

export const ArrowIcon = styled.span`
  color: #ac8440;
  svg{
    font-size: 30px;
    font-weight: bold;
  }
`;