import React, { useContext } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Container, Content, InfoArea } from "./styles";
import Carousel from "./Carousel/Carousel";
import ProductList from "./ProductsList/ProductList";
import { cookies, cookiesFit, potes,  brownies} from "./ProductsList/productsData";
import { ContextPedido } from "../../components/PedidoContext/PedidoContext";
import iconWPP from "../../assets/icons/Logowpp.svg";
import iconInstagram from "../../assets/icons/Instagram.svg";
import chuviscoTriste from "../../assets/img/Chuvisco triste.png";

const arrowUp = (
  <svg width='1em' height='1em' viewBox='0 0 16 16' className='bi bi-chevron-up' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/>
  </svg>
)

const Home = (props) => {
  const {history} = props;
  const { pedido, combos, actions } = useContext(ContextPedido);
  const {setPedido, setCombos} = actions;

  const cookieInCombo = (index, add = true) => {
    const newCombo = [...combos];
    if(add){
      newCombo[index] = combos[index] + 1;
    }else{
      newCombo[index] = combos[index] - 1;
    }
    setCombos(newCombo);
  }

  const validationCombos = () => {
    const validationError = [];
    combos.map(qtd => {
      validationError.push(qtd % 5 === 0);
    });
    if(validationError[0] === false || validationError[1] === false){
      return false;
    }
    return true;
  }

  const enviarPedido = () => {
    if(pedido.length === 0){
      toast.error('Você pode escolher seus produtos adicionando-os abaixo');
    }
    else if(validationCombos()){
      history.push('/carrinho');
    }
    else{
      toast.error('Os Cookies (ou Cookies Fit) precisam ser em combos de 5 unidades');
    }
  }

  return(
    <Container>
      <img src={chuviscoTriste} alt='chuvisco'/>
      <h4>Infelizmente o site está em manutenção no momento.</h4>
      <h1>Em breve uma nova Chuva de Cookies!</h1>
    </Container>
  )
}

export default withRouter(Home);