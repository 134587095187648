import React, {useContext, useEffect} from "react";
import { withRouter } from "react-router-dom";
import {Container, Content, Footer} from "./style";
import { ContextPedido } from "../../components/PedidoContext/PedidoContext";
import iconwpp from "../../assets/icons/Logowpp.svg";

const Carrinho = (props) => {
  const {history} = props;
  const { pedido, actions } = useContext(ContextPedido);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      actions.setPedido([]);
      actions.setCombos([0, 0]);
      history.push('/');
    }
  }, []);

  const confirmPedido = () => {
      let mensagem = 'Olá, gostaria de fazer um pedido. \n';
      pedido.forEach((item) => {
        mensagem += item;
        mensagem += '\n';
      })
      const telefone = '5581996316665';
      const texto = window.encodeURIComponent(mensagem);
      window.open(`https://api.whatsapp.com/send?phone=${telefone}&text=${texto}`, '_blank');
      history.push('/');
  }

  console.log(pedido);
  return(
    <Container>
      <h3>Seu pedido</h3>
      {
        pedido.length > 0 ?
        (
          <Content>
            <ul className='list-group'>
              {
                pedido.map(item => (
                  <li className='list-group-item'>{item}</li>
                ))
              }
            </ul>
            <Footer>
              <button type='button' className='btn btn-danger' onClick={() => history.push('/')}>
                Voltar
              </button>
              <button type='button' className='btn btn-success' onClick={confirmPedido}>
                Confirmar pedido
                <img src={iconwpp} alt='whatsapp'/>
              </button>
            </Footer>
          </Content>
        ) : (
          <h4>Você não inseriu nenhum produto no carrinho</h4>
        )
      }
    </Container>
  )
}

export default withRouter(Carrinho);