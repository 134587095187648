import React, {createContext, useState} from "react";

export const ContextPedido = createContext({
  pedido: [],
  combos: [],
  actions: {
    setPedido: () => {},
    setCombos: () => {}
  }
});

const PedidoContext = ({children}) => {
  const [pedido, setPedido] = useState([]);
  const [combos, setCombos] = useState([0, 0]);

  const value = {
    pedido, 
    combos,
    actions: {
      setPedido,
      setCombos
    }
  }

  return(
    <ContextPedido.Provider value={value} >
      {children}
    </ContextPedido.Provider>
  )
}

export default PedidoContext;