import React from "react";
import { Link } from 'react-router-dom';
import { Container, DivCollapse, NavHeader } from "./styles";
import logo from "../../assets/img/Logo_ChuvaDeCookies.png";

const Header = () => {

  return(
    <Container>
      <div className='fixed-top'>
        <NavHeader className='navbar navbar-dark'>
          <Link to='/'>
            <img src={logo} alt='logo'/>
          </Link>
          {/* <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarToggleExternalContent' aria-controls='navbarToggleExternalContent' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button> */}
        </NavHeader>
        <DivCollapse className='collapse' id='navbarToggleExternalContent'>
          <ul>
            <li>
              <Link to='/'>Cardápio</Link>
            </li>
            {/* <li>
              <Link to='/carrinho'>Carrinho</Link>
            </li> */}
            {/* <li>
            <Link to='quem-somos'>Quem somos</Link>
            </li> */}
          </ul>
        </DivCollapse>
      </div>
      </Container>
  )
}

export default Header;